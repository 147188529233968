
import { defineComponent } from '~/bridge'

export default defineComponent({
  name: 'BaseNumberMarker',
  props: {
    textColor: { type: String, default: 'ui-kit-text-secondary--text' },
    backgroundColor: { type: String, default: 'ui-kit-bg-gray-60' }
  }
})
