import Cookies from 'js-cookie'
import { CookieKey } from '~/core/enums'
import { infoClient } from '~/core/utils/clients/infoClient'
import { medcardClient } from '~/core/utils/clients/medcardClient'
import { masterClient } from '~/core/utils/clients/masterClient'

const RESOURCE = '/appointments'

export type AppointmentPreparationDetailsDto = {
  isDocumentAdded: boolean
  isMedformFull: boolean
  isMedcardSharingSessionConfirmed: boolean
  isQuestionToDoctorAdded: boolean
  userMedpoints: number
  allMedpoints: number
}

type AppointmentPrepareQuestionsPayload = {
  goal: string,
  questions: string[]
}

type EncryptedDoctorQuestionsDto = {
  goal: string
  iv: string
  questions: string
}

export default {
  getPreparationDatails(id: string) {
    return infoClient.get<AppointmentPreparationDetailsDto>(
      `${RESOURCE}/v2/preparation/${id}/`,
      { camelize: true }
    )
  },
  fullMedform(id: string) {
    return medcardClient.patch(`${RESOURCE}/v2/preparation/${id}/full_medform/`, { camelize: true })
  },
  createDoctorQuestions(id: string, payload: AppointmentPrepareQuestionsPayload) {
    return masterClient.post(`${RESOURCE}/questions_to_doctor/${id}/`, payload, {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || ''
      },
      camelize: true
    })
  },
  getDoctorQuestions(id: number) {
    return medcardClient.get<EncryptedDoctorQuestionsDto>(
      `${RESOURCE}/v2/preparation/${id}/questions/`,
      { camelize: true }
    )
  },
  getDoctorQuestionsKey(id: number) {
    return masterClient.get(`${RESOURCE}/questions_to_doctor/${id}/key/`, {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || ''
      },
      camelize: true
    })
  }
}
