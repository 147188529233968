import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { isArray } from 'lodash-es'
import { APPOINTMENT_ACTIONS, APPOINTMENT_GETTERS, APPOINTMENT_MUTATIONS } from '~/core/constants/store/appointment'
import { composeFullName, removeNamespace } from '~/core/functions'
import { FullName } from '~/core/types'
import { HttpStatusCode } from '~/core/enums'
import { MedcardSessionData } from '~/core/api/mtlink.api'
import {
  AppointmentCancelStatus,
  AppointmentStatus,
  AppointmentTransferStatus,
  AppointmentType,
  PaymentStatus,
  TelemedState
} from '~/features/Appointments/enums'
import { AppointmentApi } from '~/features/Appointments/api'
import { AppointmentEntity } from '~/features/Appointments/types/appointment'
import { AppointmentDto } from '~/features/Appointments/api/appointment.api'

const ACTIONS_TYPES = removeNamespace('appointment/', APPOINTMENT_ACTIONS)
const MUTATIONS_TYPES = removeNamespace('appointment/', APPOINTMENT_MUTATIONS)
const GETTERS_TYPES = removeNamespace('appointment/', APPOINTMENT_GETTERS)

/**
 * Todo:
 * здесь должна быть функция-фабрика, которая возвращает default state,
 * но мы тогда теряем intelliSense у редактора при обращении к state
 */
export const state = (): AppointmentEntity => ({
  avatarPath: '',
  isOnline: false,
  cancelStatus: AppointmentCancelStatus.Disallowed,
  transferStatus: AppointmentTransferStatus.Disallowed as AppointmentTransferStatus,
  currentStatus: AppointmentStatus.Upcoming as AppointmentStatus,
  datetimeVisit: '',
  id: -1,
  price: null,
  payPatientInLpu: null,
  recommendations: '',
  status: AppointmentStatus.Upcoming,
  rateDoctorId: null as number | null,
  rateLpuId: null as number | null,
  inUserFavourites: null,
  couponAvailable: false,
  priceWithClubDiscount: null,
  isClub: false as AppointmentEntity['isClub'],
  /** @deprecated Удалить вместе с флагом sff_appointment_ml_confirmed */
  confirmationIsAvailable: null,
  confirmation: null,
  documentGroupId: null,
  reappointmentIsAvailable: false,
  isMedcardAvailableForLpu: null,
  forename: '',
  patronymic: '',
  surname: '',
  birthdate: '',
  familyProfileUuid: null,
  doctorInfo: {
    name: '',
    specialityIdProdoctorov: null,
    specialityName: '',
    idProdoctorov: -1,
    urlProdoctorov: '',
    isUsingMedcard: false
  },
  lpuInfo: {
    idProdoctorov: -1,
    fullAddress: '',
    address: '',
    metroNearby: [],
    latitude: null,
    name: '',
    longitude: null,
    phone: '',
    town: ''
  },
  serviceInfo: {
    synonymName: '',
    doctorFullName: '',
    resourceName: '',
    serviceRequireConsultationName: null
  },
  type: AppointmentType.Doctor as AppointmentType,
  title: '',
  telemedInfo: {
    secondsBeforeStart: -1,
    paymentExpiresSeconds: -1,
    telemedState: TelemedState.Cancelled,
    paymentStatus: PaymentStatus.Cancelled,
    secondsUntilCompletionAvailable: -1,
    patientTimedelta: null
  },
  attachment: {
    folderIds: undefined,
    femaleCalendarId: null
  },
  timedelta: 0,
  hasQuestionsToDoctor: false
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  [GETTERS_TYPES.PATIENT_FULL_NAME](state: RootState) {
    return composeFullName(state)
  }
}

export const actions: ActionTree<RootState, RootState> = {
  async [ACTIONS_TYPES.FETCH_FULL_APPOINTMENT]({ dispatch, commit }, id) {
    commit(MUTATIONS_TYPES.SET_PATIENT_FULL_NAME, {
      surname: '',
      patronymic: '',
      forename: ''
    } as FullName)

    commit(MUTATIONS_TYPES.SET_PATIENT_BIRTHDATE, {
      birthdate: ''
    })

    await Promise.all([
      dispatch(ACTIONS_TYPES.FETCH_APPOINTMENT, { id }),
      dispatch(ACTIONS_TYPES.FETCH_APPOINTMENT_PROFILE_DATA, { id })
    ])
  },
  async [ACTIONS_TYPES.FETCH_APPOINTMENT]({ commit }, { id }) {
    try {
      const { data } = await AppointmentApi.getAppointment(id)

      commit(MUTATIONS_TYPES.SET_APPOINTMENT, data)
    } catch (e) {
      this.$captureException(e)
      throw e
    }
  },
  async [ACTIONS_TYPES.FETCH_APPOINTMENT_PROFILE_DATA]({ commit }, { id }) {
    try {
      const { data } = await AppointmentApi.getProfileData(id)

      commit(MUTATIONS_TYPES.SET_PATIENT_FULL_NAME, {
        surname: data.patientSurname || '',
        patronymic: data.patientPatronymic || '',
        forename: data.patientForename || ''
      } as FullName)

      commit(MUTATIONS_TYPES.SET_PATIENT_BIRTHDATE, {
        birthdate: data.patientBirthday
      })
    } catch (e) {
      // 404, если имени нет, в дальнейшем переделается на пустой 204
      if (e.response?.status === HttpStatusCode.NotFound) {
        commit(MUTATIONS_TYPES.SET_PATIENT_FULL_NAME, {
          surname: '',
          patronymic: '',
          forename: ''
        } as FullName)

        return
      }

      this.$captureException(e)
    }
  }
}

export const mutations: MutationTree<RootState> = {
  [MUTATIONS_TYPES.SET_APPOINTMENT](state, payload: AppointmentDto) {
    Object.assign(state, payload)
  },
  [MUTATIONS_TYPES.SET_DOCUMENT_GROUP_ID](state, payload: number | null) {
    state.documentGroupId = payload
  },
  [MUTATIONS_TYPES.CHANGE_FAVOURITE_STATUS](state, isFavorite: boolean) {
    const payload: Pick<AppointmentEntity, 'inUserFavourites'> = { inUserFavourites: isFavorite }
    Object.assign(state, payload)
  },
  [MUTATIONS_TYPES.SET_STATUS](state, status: AppointmentStatus) {
    const payload: Pick<AppointmentEntity, 'status'> = { status }
    Object.assign(state, payload)
  },
  [MUTATIONS_TYPES.SET_CURRENT_STATUS](state, currentStatus: AppointmentStatus) {
    const payload: Pick<AppointmentEntity, 'currentStatus'> = { currentStatus }
    Object.assign(state, payload)
  },
  [MUTATIONS_TYPES.SET_CANCEL_STATUS](state) {
    state.status = AppointmentStatus.Cancelled
    state.currentStatus = AppointmentStatus.Cancelled
    state.cancelStatus = AppointmentCancelStatus.Disallowed
  },
  [MUTATIONS_TYPES.SET_PATIENT_FULL_NAME](state, payload: FullName) {
    Object.assign(state, payload)
  },
  [MUTATIONS_TYPES.SET_PATIENT_BIRTHDATE](state, payload: { birthdate: DateISO }) {
    state.birthdate = payload.birthdate
  },
  [MUTATIONS_TYPES.SET_APPOINTMENT_ATTACHMENT](state, payload: MedcardSessionData) {
    if (!state.attachment) {
      state.attachment = {}
    }

    state.attachment.femaleCalendarId = payload.femaleCalendarId
    state.attachment.folderIds = isArray(payload.folderIds) ? [...payload.folderIds] : payload.folderIds
  }
}
