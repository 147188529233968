import { infoClient } from '~/core/utils/clients/infoClient'
import { profileClient } from '~/core/utils/clients/profileClient'
import { masterClient } from '~/core/utils/clients/masterClient'
import { medcardClient } from '~/core/utils/clients/medcardClient'
import { MedcardSessionData } from '~/core/api/mtlink.api'
import { AppointmentMetro } from '~/features/Appointments/types'
import {
  AppointmentCancelStatus,
  AppointmentStatus,
  AppointmentTransferStatus,
  AppointmentType,
  PaymentStatus,
  TelemedState
} from '~/features/Appointments/enums'

const RESOURCE = '/appointments'

export type AppointmentDto = {
  id: number,
  type: AppointmentType,
  title: string,
  datetimeVisit: DateTimeISO,
  avatarPath: string,
  isOnline: boolean,
  documentGroupId: number | null,
  status: AppointmentStatus,
  currentStatus: AppointmentStatus,
  cancelStatus: AppointmentCancelStatus,
  recommendations: string,
  price: number | null,
  lpuInfo: {
    name: string,
    address: string
    fullAddress: string,
    town: string,
    phone: string,
    longitude: number | null,
    latitude: number | null,
    metroNearby: AppointmentMetro[]
    idProdoctorov: number | null
  },
  /** @deprecated Удалить вместе с флагом sff_appointment_ml_confirmed */
  confirmationIsAvailable: boolean | null,
  confirmation: {
    available: boolean,
    reason: string
  } | null,
  reappointmentIsAvailable: boolean,
  isMedcardAvailableForLpu: boolean | null,
  transferStatus: AppointmentTransferStatus,
  /**
   * true - клубная запись
   * null- потенциально клубная запись
   * false - точно не клубная запись
   * */
  isClub: boolean | null,
  rateDoctorId: number | null,
  rateLpuId: number | null,
  couponAvailable: boolean,
  priceWithClubDiscount: number | null,
  inUserFavourites: boolean | null,
  payPatientInLpu: number | null
  serviceInfo: null | {
    synonymName: string
    doctorFullName: string
    resourceName: string
    serviceRequireConsultationName: string | null
  }
  doctorInfo: null | {
    name: string
    idProdoctorov: number | null
    urlProdoctorov: string | null
    specialityName: string
    specialityIdProdoctorov: number | null
    isUsingMedcard: boolean
  },
  telemedInfo: null | {
    secondsBeforeStart: number,
    paymentExpiresSeconds: number,
    telemedState: TelemedState,
    paymentStatus: PaymentStatus | null,
    secondsUntilCompletionAvailable: number,
    patientTimedelta: number | null
  },
  attachment: MedcardSessionData | null,
  timedelta: number,
  familyProfileUuid: string | null
  hasQuestionsToDoctor: boolean
}

export type AppointmentProfileDto = {
  medtochkaInfoId: number
  patientFullName: string
  patientForename: string
  patientPatronymic: string
  patientSurname: string
  patientBirthday: string
}

export type AppointmentPaymentLinkDto = {
  link: string
}

export type CreatePollAfterConsultationPayload = {
  rating: number
  comment?: string
  whatsWrong?: string[],
  device?: string
  osVersion?: string
  browser?: string
  appVersion?: string
}

export default {
  getAppointment(id: number) {
    return infoClient.get<AppointmentDto>(
      `${RESOURCE}/v2/${id}/`,
      { camelize: true }
    )
  },
  getCouponBlob(id: number) {
    return infoClient.get<Blob>(`${RESOURCE}/${id}/club_coupon/`, {
      responseType: 'blob',
      camelize: true
    })
  },
  cancel(id: number) {
    return infoClient.post(`${RESOURCE}/v2/${id}/cancel/`, {}, { camelize: true })
  },
  getProfileData(id: number) {
    return profileClient.get<AppointmentProfileDto>(`${RESOURCE}/${id}/`, { camelize: true })
  },
  deleteAppointment(id: number) {
    return masterClient.delete(`${RESOURCE}/${id}/`, { camelize: true })
  },
  confirmAppointment(id: number) {
    return infoClient.post(`${RESOURCE}/${id}/confirm/`, {}, { camelize: true })
  },
  getPaymentLink(id: number) {
    return masterClient.get<AppointmentPaymentLinkDto>(
      `${RESOURCE}/${id}/payment_link/`,
      { camelize: true }
    )
  },
  completeConsultation(id: string) {
    return medcardClient.post(`${RESOURCE}/v2/telemed/${id}/complete/`, {}, { camelize: true })
  },
  changeMedcardAvailable(id: number, isMedcardAvailable: boolean) {
    return medcardClient.patch(`${RESOURCE}/v2/${id}/lpu_medcard_access/`, {
      is_medcard_available_for_lpu: isMedcardAvailable
    }, { camelize: true })
  },
  getQuestionsForPollAfterConsultation() {
    return medcardClient.get<Record<string, string>>(`${RESOURCE}/v2/telemed/poll/whats_wrong/retrieve/`)
  },
  getPollAfterConsultation(appointmentId: number) {
    return medcardClient.get(`${RESOURCE}/v2/telemed/${appointmentId}/poll/retrieve/`)
  },
  createPollAfterConsultation(appointmentId: number, payload: CreatePollAfterConsultationPayload) {
    return medcardClient.post(`${RESOURCE}/v2/telemed/${appointmentId}/poll/create/`, payload, { camelize: true })
  },
  updateAttachment(id: number, payload: MedcardSessionData) {
    return medcardClient.post<MedcardSessionData>(`${RESOURCE}/v2/${id}/attachment/update_create/`, payload, { camelize: true })
  }
}
